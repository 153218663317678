import { AuthSigninComponent } from './demo/pages/authentication/auth-signin/auth-signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './demo/pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LabsComponent } from './demo/pages/labs/labs.component';
import { LabTestingComponent } from './demo/pages/lab-testing/lab-testing.component';
import { PackagesComponent } from './demo/pages/packages/packages.component';
import { DriversComponent } from './demo/pages/drivers/drivers.component';
import { EditLabComponent } from './demo/pages/edit-lab/edit-lab.component';
import { ViewLabsComponent } from './demo/pages/view-labs/view-labs.component';
import { ViewTestingComponent } from './demo/pages/view-testing/view-testing.component';
import { ViewPackageComponent } from './demo/pages/view-package/view-package.component';
import { CreateJobPackageComponent } from './demo/pages/create-job-package/create-job-package.component';
import { CreateJobCustomComponent } from './demo/pages/create-job-custom/create-job-custom.component';
import { CreateJobPrescriptionComponent } from './demo/pages/create-job-prescription/create-job-prescription.component';
import { PendingRequestComponent } from './demo/pages/pending-request/pending-request.component';
import { OnGoingJobComponent } from './demo/pages/on-going-job/on-going-job.component';
import { CreateAccountComponent } from './demo/pages/create-account/create-account.component';
import { LabEmployeeComponent } from './demo/pages/lab-employee/lab-employee.component';
import { CustomerListComponent } from './demo/pages/customer-list/customer-list.component';
import { CompletedJobComponent } from './demo/pages/completed-job/completed-job.component';
import { EditLabTestingComponent } from './demo/pages/edit-lab-testing/edit-lab-testing.component';
import { EditPackageComponent } from './demo/pages/edit-package/edit-package.component';

import {DataTablesModule} from 'angular-datatables';
import { RegisterChannelingCenterComponent } from './demo/pages/register-channeling-center/register-channeling-center.component';
import { AddDoctorComponent } from './demo/pages/add-doctor/add-doctor.component';
import { AddSpecializationComponent } from './demo/pages/add-specialization/add-specialization.component';
import { ChannlingListComponent } from './demo/pages/channling-list/channling-list.component';
import { EditChannelingComponent } from './demo/pages/edit-channeling/edit-channeling.component';
import { DoctorByChannelIDComponent } from './demo/pages/doctor-by-channel-id/doctor-by-channel-id.component';
import { EditDoctorComponent } from './demo/pages/edit-doctor/edit-doctor.component';
import { RequestChannelingComponent } from './demo/pages/request-channeling/request-channeling.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { QrscanjobsComponent } from './demo/pages/qrscanjobs/qrscanjobs.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    HomeComponent,
    AuthSigninComponent,
    LabsComponent,
    LabTestingComponent,
    PackagesComponent,
    DriversComponent,
    EditLabComponent,
    ViewLabsComponent,
    ViewTestingComponent,
    ViewPackageComponent,
    CreateJobPackageComponent,
    CreateJobCustomComponent,
    CreateJobPrescriptionComponent,
    PendingRequestComponent,
    OnGoingJobComponent,
    CreateAccountComponent,
    LabEmployeeComponent,
    CustomerListComponent,
    CompletedJobComponent,
    EditLabTestingComponent,
    EditPackageComponent,
    RegisterChannelingCenterComponent,
    AddDoctorComponent,
    AddSpecializationComponent,
    ChannlingListComponent,
    EditChannelingComponent,
    DoctorByChannelIDComponent,
    EditDoctorComponent,
    RequestChannelingComponent,
    AddCustomerComponent,
    QrscanjobsComponent,

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    DataTablesModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [NavigationItem],
  bootstrap: [AppComponent]
})
export class AppModule { }
