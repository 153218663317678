import { RequestChannelingComponent } from './demo/pages/request-channeling/request-channeling.component';
import { EditDoctorComponent } from './demo/pages/edit-doctor/edit-doctor.component';
import { DoctorByChannelIDComponent } from './demo/pages/doctor-by-channel-id/doctor-by-channel-id.component';
import { EditChannelingComponent } from './demo/pages/edit-channeling/edit-channeling.component';
import { EditLabComponent } from './demo/pages/edit-lab/edit-lab.component';
import { ChannlingListComponent } from './demo/pages/channling-list/channling-list.component';
import { AddSpecializationComponent } from './demo/pages/add-specialization/add-specialization.component';
import { EditPackageComponent } from './demo/pages/edit-package/edit-package.component';
import { EditLabTestingComponent } from './demo/pages/edit-lab-testing/edit-lab-testing.component';
import { LabEmployeeComponent } from './demo/pages/lab-employee/lab-employee.component';
import { CreateAccountComponent } from './demo/pages/create-account/create-account.component';
import { OnGoingJobComponent } from './demo/pages/on-going-job/on-going-job.component';
import { PendingRequestComponent } from './demo/pages/pending-request/pending-request.component';
import { CreateJobPrescriptionComponent } from './demo/pages/create-job-prescription/create-job-prescription.component';
import { CreateJobPackageComponent } from './demo/pages/create-job-package/create-job-package.component';
import { ViewPackageComponent } from './demo/pages/view-package/view-package.component';
import { ViewTestingComponent } from './demo/pages/view-testing/view-testing.component';
import { ViewLabsComponent } from './demo/pages/view-labs/view-labs.component';
import { DriversComponent } from './demo/pages/drivers/drivers.component';
import { PackagesComponent } from './demo/pages/packages/packages.component';
import { LabTestingComponent } from './demo/pages/lab-testing/lab-testing.component';

import { HomeComponent } from './demo/pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AuthSigninComponent } from './demo/pages/authentication/auth-signin/auth-signin.component';
import { LabsComponent } from './demo/pages/labs/labs.component';
import { CreateJobCustomComponent } from './demo/pages/create-job-custom/create-job-custom.component';
import { CustomerListComponent } from './demo/pages/customer-list/customer-list.component';
import { CompletedJobComponent } from './demo/pages/completed-job/completed-job.component';
import { RegisterChannelingCenterComponent } from './demo/pages/register-channeling-center/register-channeling-center.component';
import { AddDoctorComponent } from './demo/pages/add-doctor/add-doctor.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { QrscanjobsComponent } from './demo/pages/qrscanjobs/qrscanjobs.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/Login',
        pathMatch: 'full'
      },
      {
        path: 'Home',
        component: HomeComponent
      },
      {
        path: 'LabRegister',
        component: LabsComponent
      },
      {
        path: 'LabList',
        component: ViewLabsComponent
      },
      {
        path: 'TestingList',
        component: ViewTestingComponent
      },
      {
        path: 'PackageList',
        component: ViewPackageComponent
      },
      {
        path: 'LabTesting',
        component: LabTestingComponent
      },
      {
        path: 'AddPackages',
        component: PackagesComponent
      },
      {
        path: 'AddDrivers',
        component: DriversComponent
      },
      {
        path: 'PendingRequest',
        component: PendingRequestComponent
      },
      {
        path: 'CustomerList',
        component: CustomerListComponent
      },
      {
        path: 'OnGoingJob',
        component: OnGoingJobComponent
      },
      {
        path: 'CompletedJob',
        component: CompletedJobComponent
      },
      {
        path: 'CreateAccount',
        component: CreateAccountComponent
      },
      {
        path: 'AddEmployee',
        component: LabEmployeeComponent
      },
      {
        path: 'CreateJob-Package/:id',
        component: CreateJobPackageComponent
      },
      {
        path: 'CreateJob-Custom/:id',
        component: CreateJobCustomComponent
      },
      {
        path: 'CreateJob-Prescription/:id',
        component: CreateJobPrescriptionComponent
      },
      {
        path: 'EditBloodTesting/:id',
        component: EditLabTestingComponent
      },
      {
        path: 'EditPackage/:id',
        component: EditPackageComponent
      },
      {
        path: 'EditLab/:id',
        component: EditLabComponent
      },
      {
        path: 'EditChanneling/:id',
        component: EditChannelingComponent
      },
      {
        path: 'DoctorByChannelID/:id',
        component: DoctorByChannelIDComponent
      },
      {
        path: 'EditDoctor/:id',
        component: EditDoctorComponent
      },
      {
        path: 'register-center',
        component: RegisterChannelingCenterComponent
      },
      {
        path: 'Request-Channeling',
        component: RequestChannelingComponent
      },
      {
        path: 'add-doctor',
        component: AddDoctorComponent
      },
      {
        path: 'add-specialization',
        component: AddSpecializationComponent
      },
      {
        path: 'channeling-list',
        component: ChannlingListComponent
      },
      {
        path: 'add-customer',
        component: AddCustomerComponent
      },
      {
        path: 'ScanJobs',
        component: QrscanjobsComponent
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
    ]
  },
  {
    path: 'Login',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: AuthSigninComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
